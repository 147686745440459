import { store } from "../..";
import { editUserFilters } from "../../hocs/users/UpdateCurrentUserFilters";
import filtersAction from "../filters/actions";

const actions = {
	SET_STATE: "user/SET_STATE",
	LOGIN: "user/LOGIN",
	LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
	LOGOUT: "user/LOGOUT",
	SET_PLANNING_FILTER: "user/SET_PLANNING_FILTER",
	EDIT_FILTERS: "user/EDIT_FILTERS",
	SET_FAVORITE_FACILITY: "user/SET_FAVORITE_FACILITY",
	SET_FAVORITE_METER: "user/SET_FAVORITE_METER",
};

/**
 *
 * @param {{
 * type:import('../../lib/Methods/filter').filterKeys,
 * filter:import('../../graphql/generated').PlanningFilterInput,
 * }} payload
 * @param {string=} _db
 * @returns
 */
export const editUserFiltersAction = async (payload, _db) => {
	const { account } = store.getState();
	return editUserFilters({ ...payload, _db: _db || account?._db }).then(
		({
			data: {
				editFilters: {
					data: { selectedFilters },
				},
			},
		}) => {
			store.dispatch({ type: filtersAction.SET_STATE, payload: selectedFilters });
		},
	);
};

export default actions;
