import getUserFilter from "@src/lib/Methods/filter";
import { useDPReduxAppSelector } from "@src/redux";
import { IntlProvider } from "react-intl";
import englishUK from "./en-GB";
import english from "./en-US";
import swedish from "./sw-SW";

const locales = {
	"en-US": english,
	"en-GB": englishUK,
	"sv-SE": swedish,
};

const IntlLocalization = ({ children }) => {
	// Replace mapStateToProps with useSelector hook
	const settings = useDPReduxAppSelector((state) => state.settings);
	const filters = useDPReduxAppSelector((state) => state.filters);
	const selectedLocale = getUserFilter(filters as any, "selectedLocale");

	// Destructure settings with default locale
	const { locale = "sv-SE" } = settings as any;

	// Calculate derived values
	const currentLocale = locales[selectedLocale || locale] || locales["sv-SE"];

	return (
		<IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
			{children}
		</IntlProvider>
	);
};

export default IntlLocalization;
