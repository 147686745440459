import { ApolloProvider } from "@apollo/client";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/sv";
globalThis.global = globalThis.global || globalThis;
// import { DPProvider } from '@dp-ui2/components/core/_types/DPProvider';
import { GoogleOAuthProvider } from "@react-oauth/google";
import "ant-design-draggable-modal/dist/index.css";
import { Fragment, Suspense } from "react";
import { createRoot } from "react-dom/client";
import Favicon from "react-favicon";
import { Provider } from "react-redux";
import { type Store, applyMiddleware, compose, createStore } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import reducers, { type reducersMaker } from "./redux/reducers";
import sagas from "./redux/sagas";
// eslint-disable-next-line import/no-cycle
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "react-calendar-timeline/lib/Timeline.css";
import { isP2Module } from "./apps/app.util";
import DPRouter from "./apps/apps.router";
import Loader from "./components/Layout/Loader";
import { googleClientId } from "./config/config";
import client from "./config/createApolloClient";
import IntlLocalization from "./locales/Intl";
import * as serviceWorker from "./serviceWorker";

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

if (process.env.NODE_ENV === "development") {
	middlewares.push(logger);
} else {
	// eslint-disable-next-line no-console
	console.log = () => {};
}
const reduxStore: Store<ReturnType<typeof reducersMaker>> = createStore<any, any, any, any>(
	reducers(history),
	compose(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(sagas);
reduxStore.subscribe(() => {
	const {
		// @ts-ignore
		settings: { locale },
	} = reduxStore.getState();
	const lang = locale.split("-")[0].toLowerCase();
	const finalLocale = lang === "sw" ? "sv" : "en-gb";
	moment.locale(finalLocale);
});
const App = () => (
	<Fragment>
		<Favicon url={isP2Module ? `${window.location.origin}/p2Favicon.svg` : `${window.location.origin}/favicon.svg`} />
		<GoogleOAuthProvider clientId={googleClientId}>
			<ApolloProvider client={client}>
				<Provider store={reduxStore}>
					<IntlLocalization>
						<Suspense fallback={<Loader spinning fullScreen />}>
							<DPRouter history={history} />
						</Suspense>
					</IntlLocalization>
				</Provider>
			</ApolloProvider>
		</GoogleOAuthProvider>
	</Fragment>
);
const container = document.getElementById("root");
if (container) {
	const root = createRoot(container);
	root.render(<App />);
} else {
	console.error("No root element found");
}

// This is tender Method

// @ts-ignore
global.renderCount = 0;
// @ts-ignore
global.renderGCount = 0;
serviceWorker.unregister();

export { history, reduxStore as store };
