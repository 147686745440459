/* eslint-disable max-len */
/* eslint-disable no-multi-assign */
import actions from "./actions";

const initialState = {
	_id: "",
	name: "",
	role: "",
	token: "",
	firstName: "",
	lastName: "",
	accounts: [],
	email: "",
	avatar: "",
	authorized: false,
	loading: false,
	profileImage: "",
	beta: false,
};

// biome-ignore lint/style/useDefaultParameterLast: <explanation>
export default function userReducer(state = initialState, action) {
	const updateFilters = (filters, payload, userState) => {
		const dummy = JSON.parse(JSON.stringify(filters));
		const type = Object.keys(payload)[0];
		const { selectedAccount, selectedUser, ...rest } = payload[type];
		dummy[type] = rest;
		dummy.workReport = dummy.workReport || {};
		dummy.coverageReport = dummy.coverageReport || {};
		dummy.projectMonthly = dummy.projectMonthly || {};
		dummy.myPlanning = dummy.myPlanning || {};
		dummy.landingPage = dummy.landingPage || {};
		dummy.meeting = dummy.meeting || {};
		dummy.order = dummy.order || {};
		dummy.timePlanner = dummy.timePlanner || {};
		dummy.project = dummy.project || {};
		dummy.task = dummy.task || {};
		dummy.recentUsers = dummy.recentUsers || [];
		dummy.recentAccounts = dummy.recentAccounts || [];
		if (payload.selectedLocale) {
			dummy.filters = { selectedLocale: payload.selectedLocale };
			dummy.selectedFilters = userState.selectedFilters;
			dummy.selectedFilters.general = { ...userState.selectedFilters?.general, selectedLocale: payload.selectedLocale };
		}
		const selectedBrowserLocale = window.navigator.language;
		const defaultLocale = ["en-US", "sv-SE"].includes(selectedBrowserLocale) ? selectedBrowserLocale : "en-US";
		dummy.selectedLocale = payload.selectedLocale || defaultLocale;
		if (selectedAccount) {
			dummy.projectMonthly.selectedAccount =
				dummy.meeting.selectedAccount =
				dummy.order.selectedAccount =
				dummy.timePlanner.selectedAccount =
				dummy.landingPage.selectedAccount =
				dummy.project.selectedAccount =
				dummy.task.selectedAccount =
					selectedAccount || null;
		} else {
			dummy.timePlanner.selectedAccount =
				dummy.project.selectedAccount =
				dummy.task.selectedAccount =
					selectedAccount || null;
		}
		if (type === "project" || type === "task" || type === "timePlanner" || type === "projectMonthly") {
			dummy.project.selectedUser =
				dummy.task.selectedUser =
				dummy.timePlanner.selectedUser =
				dummy.projectMonthly.selectedUser =
					selectedUser || null;
		}
		return dummy;
	};
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		case actions.SET_PLANNING_FILTER: {
			const user = { ...state, filters: updateFilters(state.selectedFilters.general, action.payload, state) };
			global.localStorage.setItem("userInfo", JSON.stringify(user));
			return user;
		}
		case actions.SET_FAVORITE_FACILITY: {
			const user = {
				...state,
				settings: {
					...state.settings,
					favorites: {
						...state?.settings?.favorites,
						facility: action.payload,
					},
				},
			};
			global.localStorage.setItem("userInfo", JSON.stringify(user));
			return user;
		}
		case actions.SET_FAVORITE_METER: {
			const user = {
				...state,
				settings: {
					...state.settings,
					favorites: {
						...state?.settings?.favorites,
						meter: action.payload,
					},
				},
			};
			console.log(user.settings.favorites);

			global.localStorage.setItem("userInfo", JSON.stringify(user));
			return user;
		}
		default:
			return state;
	}
}
