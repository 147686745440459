import antdData from 'antd4/lib/locale-provider/en_GB'
import messages from './old_translations/en-us.json'
import DPPartsEnLocale from '@dp-parts/locales/translations/en-US.json'
import DPUIEnLocale from '@dp-ui/locales/translations/en-US.json'
import DPUI2EnLocale from '@dp-core/lang/_autogen/en-US.json'

export default {
  locale: 'en-GB',
  antdData,
  messages: {
    ...DPUI2EnLocale, ...DPUIEnLocale, ...messages, ...DPPartsEnLocale,
	openStatsView: "Open Stats View",
		recentSelections: "Recent Selections",
		allFacilities: "All Facilities",
		receiverEmail: "Receiver Email",
		downloadTemplate: "Download Template",
		deleteMeter: "Delete Meter",
		deleteMeterConfirmation: "Are you sure you want to delete this meter?",
		deleteMeterWithReadings: "Delete Meter with Readings",
	},
};
